@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abel&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div#root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

:root {
	--color-principal: #0098d7;
	--color-secundario: #f5ba00;
	--color-terciario: #3b6075;
	--color-tipografia: #2a2a2a;
	--tipografia-titulo: "Roboto Slab",serif;
	--tipografia-contenido: "Open Sans",sans-serif;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Roboto Slab", serif;
}
h1,
h2 {
  font-size: 1.8rem;
}
h3 {
  font-size: 1.4rem;
}

body {
  position: relative;
  z-index: 0;
}

.container {
  width: 1200px;
  max-width: 95vw;
  margin: auto;
}

img.img-fluid {
  width: 100%;
  height: auto;
}

.contact-form {
}
.mainLoader {
  margin: 200px auto;
  width: 100px;
}
.input-group {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  justify-content: space-between;
  margin-bottom: 30px;
}

.input-group input {
  width: 100%;
  padding: 6px 12px;
  display: block;
  height: 45px;
  line-height: 1.42857143;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

.input-group textarea {
  width: 100%;
  padding: 6px 12px;
  display: block;

  font-family: "Open Sans", sans-serif;
  border-radius: 0;
  border: 2px solid #f0f2f5;
  background: #f0f2f5;
  box-shadow: none !important;
  color: #555;
}

.btn-primary {
  background-color: #0098d7;
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all 0.25s ease;
  text-decoration: none !important;
  padding: 18px 35px;
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.3rem;
  border-radius: 20px;
  min-width: 135px;
  text-align: center;
  display: inline-block;
  text-transform: uppercase;
}

.btn-primary:hover {
  cursor: pointer;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

.tarjeta-curso {
  width: 350px;
  min-height: 350px;
  background: white;
  margin: auto;
  border: 1px solid #eaeaea;
  transition: ease all 0.3s;
}
.tarjeta-curso:hover {
  cursor: pointer;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
}

.tarjeta-curso h2.title {
  color: black;
  font-size: 1.2rem;
  margin: 20px auto;
  padding: 0 10px;
  text-align: center;
}

.tarjeta-curso span {
  display: block;
  text-align: center;
  color: #999;
}

.tarjeta-curso img {
  width: 100%;
}

.img-squared {
  width: 100%;
  height: 100%;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
}

@media only screen and (max-width: 900px) {
  .container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 499px) and (min-width: 320px) {
  a.invisible-link div.tarjeta-curso {
    width: 240px;
    min-height: -webkit-max-content;
    min-height: max-content;
  }
}

.mobile-menu {
  display: none;
}

div#root div.container-header {
  padding: 0;
}

nav {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  height: 77px;
  align-items: center;
  justify-content: space-between;
  background: white;
}

nav span {
  display: none;
}

nav a img {
  max-height: 55px;
}

nav span:hover {
  color: #0098d7;
}

nav ul {
  display: flex;
  padding: 0;
  margin: 0;
}

nav ul li {
  list-style: none;
  margin: 0;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  line-height: 40px;
  padding: 0 20px;
}

nav ul li a {
  color: black;
  transition: ease all 0.3s;
}

nav ul li a.active:after {
  content: " ";
  display: block;
  background: #0098d7;
  width: 100%;
  height: 3px;
  padding: 0;
}

nav ul li a:hover {
  color: #0098d7;
}

nav ul li.nav-btn {
  background: #0098d7;
  height: 40px;
  border-radius: 12px;
}

nav ul li.nav-btn a {
  color: white;
}

@media only screen and (max-width: 900px) {
  nav ul {
    display: none;
  }

  nav span {
    display: block;
  }

  .mobile-menu.open {
    display: block;
  }

  .mobile-menu ul {
    display: block;
    border-top: 4px solid #eaeaea;
  }

  .mobile-menu ul li {
    list-style: none;
    padding: 0 20px;
    background: #fafafa;
    font-family: "Roboto Slab", serif;
    font-weight: 700;
    line-height: 50px;
    border-bottom: 1px solid #eaeaea;
  }

  .mobile-menu ul li a {
    color: #666;
    transition: ease all 0.3s;
  }

  .mobile-menu ul li a:hover {
    color: #0098d7;
  }

  .mobile-menu ul li:hover a {
    color: #0098d7;
  }
}

section.section-title {
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 320px) {
  nav a img {
    max-height: 40px;
  }

  section.section-title {
    height: 90px;
  }
}

.nosotros-holder {
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  padding: 0 10%;
}

.nosotros-holder h2 {
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}

.nosotros-holder p {
  margin-bottom: 1.2rem;
}

.form-nosotros ul {
  display: flex;
  background: #0098d7;
  color: white;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  padding: 1% 1% 0;
  font-weight: 700;
  border: none;
}

.form-nosotros ul li {
  margin-right: 30px;
}
.form-nosotros .react-tabs__tab--selected {
  padding: 20px;
  border-radius: 0;
  border: none;
}

.react-tabs__tab-panel {
  margin-top: 30px;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .nosotros-holder p {
    font-size: 4vw;
  }

  .cuarta-seccion-nosotros #react-tabs-2 {
    margin-right: 0;
  }

  .cuarta-seccion-nosotros li#react-tabs-2,
  li#react-tabs-0 {
    text-align: center;
  }

  .form-nosotros .contact-form,
  .cuarta-seccion-nosotros p,
  .primera-seccion-nosotros {
    padding: 0 10%;
  }

  form.contact-form button.button-nosotros-form {
    min-width: 100px;
    padding: 8px 25px;
  }

  img.img-fluid {
    margin: 20px 0;
  }

  ul.react-tabs__tab-list {
    display: flex;
    justify-content: space-evenly;
  }

  li#react-tabs-2,
  li#react-tabs-0 {
    padding: 20px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 426px) {
  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    align-items: center;
  }

  .nosotros-holder p {
    font-size: 0.8rem;
  }

  .form-nosotros ul {
    display: flex;
  }

  .nosotros-holder div {
    width: 100%;
  }
}

.section-title{
    height: 168px;
    background-size: cover;
    overflow: hidden;
    display: flex;
}

.section-title h1{
    color:white;
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0,0,0,.3);
}
@media only screen and (max-width: 900px) {
    .section-title h1{
        font-size: 1.3rem;
    }
}
.label-inscripcion {
  margin-bottom: 5px;
  color: #aaa;
}

.label-inscripcion::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.datos-personales h1 {
  margin-bottom: 20px;
}

.pago-title,
.pedido-title {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: black;
}

table.shop_table.woocommerce-checkout-review-order-table {
  margin-bottom: 25px;
}

td,
th {
  color: rgb(168 167 168);
}

span.pago-option {
  display: inline-block;
  margin-bottom: 5px;
}

.card {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  padding: 20px;
  background-color: #f0f0ed;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.input-pago__fecha,
.input-pago {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

#ccNo,
#cvv {
  width: 100%;
  height: 45px;
  padding: 6px 12px;
  background-color: #fafafa;
  border: none;
  color: #555;
  font-family: "Open Sans", sans-serif;
}

div.input-pago__fecha {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0;
}

.input-pago__fecha label {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.input-pago__fecha input {
  width: 50%;
  height: 40px;
  font-family: "Open Sans", sans-serif;
  font-size: 0.9rem;
  margin-top: 5px;
}

span.pago-option {
  font-size: 1rem;
  font-family: "Roboto Slab";
  font-weight: 500;
}

select {
  margin-top: 5px;
  width: 30%;
  height: 40px;
}

select option {
  font-family: "Open Sans", sans-serif;
}

.mensaje-cvs {
  margin-top: 5px;
  color: #aaa;
}

.mensaje-cvs::after {
  content: "*";
  color: #f13e3e;
  border-bottom: 2px dotted #f13e3e;
  margin-left: 5px;
}

.submit-section {
  display: flex;
  flex-direction: column;
}

.submit-section img {
  width: 75%;
  margin: 0 auto 15px auto;
}

.submit-section input {
  margin: 0 auto;
}

.product-name,
.product-total,
.cart-subtotal th,
.order-total th,
span.woocommerce-Price-currencySymbol,
bdi {
  color: black;
}
.test {
  padding: 15px;
  text-align: center;
  margin-bottom: 15px;
  color: #fff;
}

.error {
  background-color: red;
}

.success {
  background-color: rgb(7, 160, 7);
}

.pending {
  background-color: yellow;
  color: black !important;
}

.acceder-holder {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 45px;
}

@media only screen and (max-width: 768px) {
  .acceder-holder {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr;
    grid-gap: 10px 0;
  }

  .datos-personales {
    grid-row-start: 1;
    grid-row-end: 2;
    height: -webkit-max-content;
    height: max-content;
  }

  .pedido {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  #tcoCCForm {
    height: 1370px;
  }

  h3.pago-title,
  h3.pedido-title {
    font-size: 1.5rem;
  }

  h3.pedido-title {
    margin-bottom: 20px;
  }

  h3.pago-title {
    margin-top: 30px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 768px) {
  select {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .datos-personales {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .input-pago__fecha,
  .input-pago {
    margin: 0 auto 10px auto;
  }

  #ccNo,
  #cvv {
    height: 30px;
  }

  .mensaje-cvs {
    font-size: 4vw;
  }

  div.input-pago__fecha {
    flex-direction: column;
    align-items: center;
  }

  span.pago-option {
    font-size: 0.8rem;
  }

  .input-pago__fecha label {
    width: 100%;
    margin-bottom: 10px;
  }

  .input-pago__fecha label br {
    display: none;
  }

  select {
    width: 45%;
  }

  .input-pago__fecha label div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submit-section {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  .submit-section img {
    width: 45%;
    margin: 0;
  }

  .submit-section input {
    width: 45%;
    margin: 0;
  }
}

footer {
  width: 100%;
  background-color: /*#195f78*/ var(--color-principal);
  color: white;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  font-family: Roboto, sans-serif;
  color: white;
}

footer h4 {
  font-size: 1rem;
  font-weight: 200;
}

footer .container {
  margin: 0;
  width: 100%;
  max-width: none;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 50px 0;
}

footer .container .acerca,
footer .container .contacto,
footer .container .paginas,
footer .container .blog-footer {
  margin: 0 45px;
}

footer h3 {
  color: white;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 40px;
}

footer .paginas ul {
  padding-left: 20px;
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
}

footer .paginas ul li {
  width: -webkit-max-content;
  width: max-content;
  font-family: "Open Sans", sans-serif;
  font-size: 1.4rem;
  margin-bottom: 5px;
}

footer .paginas ul li a {
  color: white;
  transition: ease all 0.3s;
  font-size: 1rem;
  font-weight: 200;
}

footer .paginas ul li a:hover {
  color: goldenrod;
}

footer .paginas,
footer .blog-footer {
  width: -webkit-max-content;
  width: max-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

footer .blog-footer .entries {
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}

footer .blog-footer .entry {
  display: flex;
  width: 100%;
}

footer .blog-footer .entry .img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

footer .blog-footer .entry .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

footer .blog-footer .entry .title {
  width: 200px;
  display: flex;
  align-items: flex-start;
}

/*
Para las direcciones
*/

.ul_dir {
  list-style: none;
  text-align: center;
}

footer .container h3 {
  font-size: 1.3rem;
  font-family: "lato", serif;
}

.contacto a {
  color: white;
}

.numeros {
  margin: 15px 0;
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
}

footer a,
footer p,
footer .paginas ul li {
  font-size: 0.9rem;
}

.derechos-reservados {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.derechos-reservados__texto {
  font-size: 0.9rem;
  font-family: "Open Sans", sans-serif;
  color: white;
}

.seccion-links {
  display: flex;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
}

.footer_titulo--mayuscula::first-letter {
  text-transform: uppercase;
}

@media only screen and (max-width: 1024px) {
  footer {
    height: -webkit-max-content;
    height: max-content;
    padding: 30px 40px;
  }

  footer .blog-footer .entries {
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  footer .container {
    flex-direction: column;
    padding: 0;
    margin: 0;
    width: 100%;
    align-items: center;
  }

  footer .container h3 {
    width: -webkit-max-content;
    width: max-content;
    margin: 0 auto 20px;
  }

  footer h4 {
    text-align: left;
  }

  footer .blog-footer,
  footer .paginas {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .derechos-reservados__texto {
    text-align: center;
  }

  footer .blog-footer .entry .img {
    flex: none;
  }

  footer .paginas ul li a {
    font-size: 1rem;
  }

  footer .container div {
    display: flex;
    align-items: center;
    text-align: center;
    margin-right: 0;
  }

  footer .container div.paginas {
    text-align: left;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.nav-tabs > li > a,
.member-name,
.section-title,
.user-name,
.heading_font,
.item-title,
.acomment-meta,
[type="reset"],
.bp-subnavs,
.activity-header,
table,
.widget_categories ul li a,
.sidebar-area .widget ul li a,
.select2-selection__rendered,
blockquote,
.select2-chosen,
.vc_tta-tabs.vc_tta-tabs-position-top
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
.vc_tta-tabs.vc_tta-tabs-position-left
  .vc_tta-tabs-container
  .vc_tta-tabs-list
  li.vc_tta-tab
  a,
body.distance-learning .btn,
body.distance-learning .vc_btn3 {
  font-family: "Roboto Slab";
  color: #222222;
}

footer h1,
footer h2,
footer h3,
footer h4,
footer h5,
footer h6 {
  font-family: Roboto, sans-serif;
  color: white;
}

.section-title-tipo {
  height: -webkit-max-content;
  height: max-content;
  overflow: hidden;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.tipo {
  position: relative;
  top: 14px;
}

.input-group {
  /* position: relative; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  /* -ms-flex-wrap: wrap; */
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  /* margin-bottom: 5px; */
}

.woocommerce-checkout-review-order-table {
  width: 100%;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

.shop_table tr {
  border: 0;
}

.shop_table > tbody > tr td {
  padding: 30px 8px;
  vertical-align: middle;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce-checkout-review-order-table td.product-name,
.woocommerce-checkout-review-order-table th {
  text-align: left;
}

.woocommerce .shop_table.order_details .product-total,
.woocommerce-checkout-review-order-table td,
.woocommerce-checkout-review-order-table th.product-total {
  text-align: right;
}

.woocommerce-checkout-review-order-table th,
.woocommerce-checkout-review-order-table.shop-table > tbody > tr > td,
.woocommerce-checkout-review-order-table.shop_table td,
.woocommerce-checkout-review-order-table.shop_table th,
.woocommerce-checkout-review-order-table > tbody > tr > td {
  padding: 6px 17px;
}

.woocommerce .shop_table.order_details > tbody > tr:nth-of-type(2n + 1),
.woocommerce-checkout-review-order-table > tbody > tr:nth-of-type(2n + 1) {
  background-color: #f2f2f2;
}

.shop_table > tbody > tr {
  border: 0;
}

.info-container {
  height: -webkit-max-content;
  height: max-content;
  display: grid;
  grid-template-columns: 1fr 2fr 3fr 3fr 2fr 1fr;
  grid-template-rows: 1fr 4fr 1fr;
}

.info-container img {
  width: 100%;
  grid-column-start: 2;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 5;
  align-self: end;
}

.info-container .text {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row-start: 1;
  grid-row-end: 4;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text p,
.text span {
  margin: 10px 0;
}

.info-container .text .pago-anual {
  margin-bottom: 0;
  font-size: 1.8rem;
  font-weight: 500;
}

.info-container .text .precio {
  margin-top: 0;
  color: rgb(0, 152, 215);
  font-size: 3vh;
  font-weight: 500;
}

.precio .usd {
  font-size: 2.5rem;
  font-weight: bolder;
}

.text p {
  font-size: 1.2rem;
}

.text p a {
  color: black;
  transition: 0.2s;
}

.text p a:hover {
  transition: 0.3s;
  color: rgb(0, 152, 215);
}

.girl-info {
  width: 100%;
  background-image: url(/static/media/orange-background.52fd46a1.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
}

.girl-info__text {
  display: flex;
  flex-direction: column;
  grid-column-start: 2;
  grid-column-end: 3;
  justify-content: center;
}

.girl-info__text--title {
  font-family: Roboto;
  text-transform: uppercase;
  color: white;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 37px;
  margin-bottom: 30px;
}

.girl-section {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.girl-image {
  width: 100%;
  grid-column-start: 3;
  grid-column-end: 4;
}

.kids-courses {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

span.course {
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1px;
}

.cercle-blue,
.cercle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  display: inline-block;
  margin-right: 5px;
}

.cercle-blue {
  background-color: rgb(0, 152, 215);
}

div.courses-logo img {
  width: 15%;
  margin-right: 10px;
}

.info-pay-section {
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr;
  grid-gap: 0 30px;
  margin: 50px 0;
}

.pay-includes {
  grid-column-start: 2;
  grid-column-end: 3;
}

.pay-actualizations {
  grid-column-start: 3;
  grid-column-end: 4;
}

.pay-includes,
.pay-actualizations {
  display: flex;
  flex-direction: column;
}

svg.svg-inline--fa.fa-check.fa-w-16 path,
svg.svg-inline--fa.fa-circle.fa-w-16 path {
  color: #0098d7;
}

.check-section {
  display: grid;
}

.check-text {
  display: flex;
  align-items: flex-start;
}

div.chech-text svg.svg-inline--fa.fa-w-16 {
  width: 10px;
}

.check-text p {
  margin-bottom: 4px;
  margin-left: 5px;
  font-size: 1.1vw;
}

h3 {
  margin-bottom: 30px;
}

.guarantee-section {
  background-color: #f5f5f5;
  display: grid;
  grid-template-columns: 1fr 5fr 5fr 1fr;
  padding: 30px 0;
  background-image: url(/static/media/04-1.acb3103e.webp);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.conditions {
  grid-column-start: 2;
  grid-column-end: 3;
}

.guarantee {
  grid-column-start: 3;
  grid-column-end: 4;
  background-color: white;
  border: 3px solid #a2a2a2;
  border-radius: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  width: 33vw;
  font-family: Roboto;
  font-size: 1vw;
  margin: 0 auto;
}

.guarantee p {
  text-align: center;
  font-family: Roboto;
  line-height: 25px;
  font-weight: 400;
}

.guarantee img {
  width: 30%;
  margin-top: 20px;
}

.fa-circle {
  background-color: #0098d7;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

.conditions h2,
.guarantee h2 {
  margin-bottom: 20px;
  font-family: Roboto;
}

.conditions h2 {
  text-transform: uppercase;
  font-size: 1.7vw;
  font-weight: 900;
}

.guarantee h2 {
  font-size: 3vh;
  display: inline-block;
  text-align: center;
}

.container-form {
  padding: 20px 130px;
  width: 100%;
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  .info-container {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    padding: 10px 15px;
    grid-gap: 0;
  }

  .info-container img {
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 0;
    width: 100%;
  }

  .info-container .text {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .section-title-tipo {
    height: 100%;
  }

  .text p {
    margin: 5px 0;
    font-size: 1.8vw;
    font-weight: 400;
  }

  .info-container .text .pago-anual {
    font-size: 2.3vw;
  }

  .precio .usd {
    font-size: 3vw;
  }

  .info-container .text .precio {
    font-size: 2vw;
  }

  .text p,
  .text span {
    margin-bottom: 0;
  }

  .girl-info {
    grid-template-columns: 1fr 1fr;
    padding: 10px 20px 0 20px;
  }

  .girl-info__text {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .girl-section {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .girl-info__text--title {
    font-size: 2.5vw;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .info-pay-section {
    grid-template-columns: 1fr 1fr;
    padding: 0 20px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 2;
    grid-column-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 2.3vw;
    margin-bottom: 30px;
  }

  .check-text p {
    font-size: 1.6vw;
  }

  .guarantee-section {
    grid-template-rows: 0.5fr 0.5fr;
    grid-template-columns: 1fr;
    padding: 20px 60px;
    grid-gap: 20px 0;
    background-size: contain;
    background-position-x: right;
    background-position-y: bottom;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 50vw;
    height: 50vh;
  }

  .conditions h2 {
    font-size: 2.5vw;
  }

  svg.svg-inline--fa.fa-circle.fa-w-16 {
    width: 10px;
    height: 10px;
  }

  div.conditions div.check-text p {
    font-size: 1.8vw;
    font-weight: 400;
  }

  .guarantee {
    height: -webkit-max-content;
    height: max-content;
    margin: auto auto;
    width: 70%;
    height: max-content;
    padding: 20px;
  }

  .guarantee p {
    font-size: 2vw;
    line-height: 20px;
  }

  .guarantee img {
    width: 25%;
  }
}

@media only screen and (max-width: 600px) and (min-width: 320px) {
  .container-form {
    width: 100%;
    padding: 10px 30px;
  }
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    height: -webkit-max-content;
    height: max-content;
  }

  .info-container img {
    display: none;
  }

  .info-container .text .pago-anual {
    font-size: 8vw;
    line-height: 30px;
    margin-bottom: 5px;
  }

  .info-container .text .precio {
    font-size: 5vw;
  }

  .precio .usd {
    font-size: 6vw;
  }

  .text p,
  .text span {
    font-size: 1rem;
  }

  .girl-info {
    grid-template-columns: 1fr;
    padding: 10px 30px;
  }

  .girl-image {
    display: none;
  }

  .girl-info__text--title {
    font-size: 5vw;
    margin-bottom: 10px;
    line-height: 25px;
  }

  .kids-courses {
    margin-bottom: 10px;
  }

  span.course {
    font-size: 0.7rem;
  }

  .cercle-blue,
  .cercle {
    width: 10px;
    height: 10px;
  }

  .courses-logo {
    margin: 0;
    display: flex;
    justify-content: space-between;
  }

  .info-pay-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    grid-gap: 20px 0;
    margin: 30px 0;
    padding: 0 30px;
  }

  .pay-includes {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .pay-actualizations {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  .pay-includes h3,
  .pay-actualizations h3 {
    font-size: 5vw;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .check-text p {
    font-size: 3.5vw;
  }

  .check-text {
    align-items: center;
  }

  .text p,
  .text span {
    font-size: 3.5vw;
  }

  .guarantee-section {
    grid-template-columns: 1fr;
    grid-template-rows: 0.5fr 0.5fr;
    padding: 30px 30px;
  }

  .conditions h2 {
    font-size: 4vw;
  }

  .conditions {
    grid-row-start: 1;
    grid-row-end: 2;
    grid-column-start: 1;
    grid-column-end: 2;
  }

  .guarantee {
    grid-row-start: 2;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 2;
    margin: 20px 0;
    width: 100%;
    padding: 15px 10px;
    height: -webkit-max-content;
    height: max-content;
  }

  div.check-text svg:not(:root).svg-inline--fa {
    margin: 0;
    margin-bottom: auto;
  }

  div.guarantee h2 {
    text-align: center;
    margin: 0 0 20px 0;
  }

  .guarantee p {
    font-size: 3vw;
    font-weight: 600;
    /* text-align: left; */
    line-height: 20px;
  }

  .guarantee p br {
    display: none;
  }

  .guarantee img {
    margin: 20px 0 0 0;
  }
}

@media only screen and (max-width: 1200px) and (min-width: 769px) {
  .info-container .text .pago-anual {
    font-size: 1.4rem;
  }

  .precio .usd {
    font-size: 2rem;
  }

  .text p {
    font-size: 1rem;
  }
}

.post-title {
  margin-bottom: 30px;
}

.main-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 50px;
}

.container-publicaciones {
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-gap: 70px;
  margin: 60px 0;
  padding: 0 100px;
}

.blog-container {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  margin-top: 50px;
}

.blog-container main h2 {
  text-transform: uppercase;
}

.post-content p,
.post-content h2 {
  margin: 30px 0;
}

.post-meta {
  border-top: 1px solid #eaeaea;
  padding: 12px 0;
  font-size: 0.8rem;
  font-family: "Roboto Slab", serif;
}

.post-meta svg {
  margin-right: 5px;
  color: #0098d7;
}

.post-content {
  margin: 30px 0;
  font-family: "Open Sans", sans-serif;
}
.post-content p {
  text-align: justify;
}
.post-content h1,
.post-content h2,
.post-content h3,
.post-content h4,
.post-content h5,
.post-content h6 {
  margin: 20px 0;
  text-transform: none !important;
}

.img-squared {
  width: 83px;
  height: 50px;
  position: relative;
}
.img-squared img {
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 60px;
  object-fit: cover;
  z-index: 2;
}

.sidebar-decorator {
  height: 3px;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(0, 152, 215, 1) 35%,
    rgba(255, 255, 255, 1) 35%,
    rgba(255, 255, 255, 1) 41%,
    rgba(0, 0, 0, 1) 41%,
    rgba(0, 0, 0, 1) 100%
  );

  margin-bottom: 30px;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 10px;
}

.card-container::after {
  width: 50px;
  height: 3px;
  background-color: #0098d7;
  content: " ";
  margin-top: 30px;
}

.card-container::before {
  width: 700px;
  height: 1px;
  background-color: #dcdcdc;
  position: absolute;
  bottom: left;
  margin-top: 10px;
}

.post-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  width: 100%;
}

.post-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.sidebar-post-item {
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 3fr;
}

.sidebar-post-item:hover h2.title {
  color: #0098d7;
  transition: ease all 0.3s;
}
.sidebar-post-item h2.title {
  font-size: 0.9rem;
  color: black;
  text-align: left;
}

.title-blog {
  font-size: 1.5rem;
}

.fecha {
  width: 60px;
  height: 60px;
  border: 2px solid #eab830;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: content-box;
  padding-bottom: 8px;
  margin-right: 10px;
}

.fecha__numero {
  color: #eab830;
  width: -webkit-max-content;
  width: max-content;
  height: 35px;
  margin: 0 auto;
  font-size: 2rem;
}

.fecha__iniciales {
  color: #8498df;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
  margin: 0 auto;
}

.content-container {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.content {
  display: flex;
  flex-direction: column;
}

.separador {
  width: 31px;
  height: 3px;
  background-color: #0098d7;
  margin-top: 30px;
}

.sidebar-section {
  margin: 60px 0;
}

.first-section-sidebar {
  margin-top: 0;
}

.sidebar-search {
  margin-top: 25px;
  background-color: #f0f2f5;
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.sidebar-search input {
  border: none;
  height: 38px;
  width: 85%;
  padding-left: 20px;
}

.sidebar-search input:focus {
  outline: none;
}

.sidebar-search button {
  border: none;
  height: 38px;
  width: 10%;
  padding-right: 20px;
  padding-left: 5px;
  background-color: #fff;
}

.search-container {
  width: 70%;
  background-color: #fff;
}

.fa-search {
  color: #0098d7;
}

.paginas {
  margin-right: 30px;
}

.blog {
  margin-left: 30px;
}

.font-black {
  margin-top: 20px;
  color: black;
  font-size: 1rem;
}

/*

    Pagination

*/

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;

}

.pagination li {
  margin-right: 10px;
}

.pagination > .active > a {
  background-color: #47ccde;
  border-color: #47ccde;
  color: #fff;
}
.pagination > li > a {
  border: 2px solid #47ccde;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #47ccde;
  border-color: #47ccde;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #47ccde;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

@media only screen and (max-width: 425px) {
  .container-publicaciones {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 60px auto;
  }

  .main-section {
    grid-template-columns: 1fr;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
  }

  .title-blog {
    font-size: 1rem;
  }

  .fecha {
    width: 30px;
    height: 30px;
  }

  .fecha__numero {
    font-size: 1.2rem;
  }

  .fecha__iniciales {
    font-size: 0.8rem;
  }

  .font-black-blog {
    margin-top: 10px;
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 424px) and (min-width: 1px) {
  .blog-container{
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) and (min-width: 425px) {
  .blog-container {
    display: block;
  }

  .sidebar-section {
    display: none;
  }

  .main-section {
    grid-template-columns: 1fr;
    grid-gap: 50px 0;
  }

  .container-publicaciones {
    grid-template-columns: 1fr;
    padding: 0;
  }

  .pagination {
    justify-content: center;
  }

  ul.pagination li.previus a {
    font-size: 3vw;
  }

  .card-container {
    width: 85%;
    margin: 0 auto;
  }

  div div.content h2.title {
    font-size: 1.3rem;
  }
}

.breadcrumbs{
    background: #f0f0f0;
    color:#aaa;
    font-family: 'Open Sans', sans-serif;
    font-size: .7rem;
    padding: 10px 0;
    text-transform: uppercase;
}
.bc-list{
    list-style: none;
    display: flex;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
}

.bc-list li span{
    margin-left: 5px;
}

.bc-list li a{
    color:#aaa;
    text-transform: uppercase;
}

.contacto-holder{
    margin: 50px auto;
    max-width: 600px;
}
@media only screen and (max-width: 900px) {
    .contacto-holder{
        margin: 50px 20px;
    }
}
.input-group .nombre,
.input-group .email {
  width: 47%;
}

.svg.svg-inline--fa.fa-caret-left.fa-w-6 path {
  color: white;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .input-group .nombre,
  .input-group .email {
    width: 100%;
    margin-bottom: 10px;
  }
}

.btn-inscribirse {
  margin-bottom: 10px;
}

.all-cursos {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 100px auto 50px auto;
  grid-column-gap: 30px;
  -webkit-column-gap: 30px;
          column-gap: 30px;
  grid-row-gap: 50px;
  row-gap: 50px;
}
.curso-single {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-column-gap: 50px;
  -webkit-column-gap: 50px;
          column-gap: 50px;
  margin-top: 50px;
}
.curso-single h2 {
  font-size: 2.2rem;
}
.meta-categorias {
  margin-top: 30px;
  display: flex;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  align-items: center;
}
.meta-categorias h3 {
  font-size: 0.8rem;
  font-weight: 400;
  color: #666;
}

.meta-categorias a {
  display: inline-block;
  margin-right: 5px;
  color: black;
  cursor: inherit;
}
.meta-categorias .icon {
  font-size: 2rem;
  color: #0098d7;
}

.single-tabs {
  width: 100%;
}

.single-tabs .react-tabs__tab {
  bottom: 0;
}
.single-tabs .react-tabs ul {
  background: #f0f4fa;
  color: #273044;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  margin-top: 0;
  padding-top: 0;
  padding-left: 0;
  font-weight: 700;
}

.single-tabs .react-tabs ul li {
  margin-right: 30px;
}
.single-tabs .react-tabs .react-tabs__tab--selected {
  padding: 20px;
  border-radius: 0;
  border: none;
  background: white;
}

.single-tabs .react-tabs__tab-panel {
  margin-top: 30px;
}
#div-detalle {
  margin-bottom: 50px;
}
#div-detalle p {
  margin: 20px 0;
  font-family: "Open Sans", sans-serif;
  text-align: justify;
}

#div-detalle h2 {
  margin: 20px 0;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}
#div-detalle ul {
  margin-left: 20px;
  background: none;
}

#div-detalle ul li {
  margin: 10px 0;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  font-weight: 400;
}
.curriculum-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  background: #f0f4fa;
  color: #273044;
  margin-bottom: 3px;
  padding: 10px;
  cursor: pointer;
}
.curriculum-row .curriculum-number {
  min-width: 30px;
  text-align: left;
  color: #999;
}
.curriculum-row .curriculum-icon {
  color: #0098d7;
}
.curriculum-row .curriculum-label {
  flex: 1 1;
  font-family: Roboto, serif;
  font-weight: 700;
  transition: ease all 0.3s;
}

.curriculum-row .curriculum-label:hover {
  color: #0098d7;
}
.curriculum-row .curriculum-duration {
  color: #999;
}

.single-tabs .Collapsible {
  border: 1px solid #eaeaea;
  margin-bottom: 20px;
}

.single-tabs .Collapsible .Collapsible__trigger {
  padding: 20px 40px;
  display: block;
  cursor: pointer;
}

.single-tabs .Collapsible .Collapsible__trigger {
  font-family: Roboto, serif;
  font-weight: 700;
  transition: ease all 0.3s;
}

.single-tabs .Collapsible .Collapsible__trigger.is-open {
  border-top: 3px solid #0098d7;
}

.single-tabs .Collapsible .Collapsible__contentInner {
  text-align: justify;
  font-family: "Open Sans", sans-serif;
  padding: 0 40px 20px 40px;
}
iframe {
  display: none;
}
@media only screen and (max-width: 1200px) {
  .all-cursos {
    grid-template-columns: repeat(2, 1fr);
  }
}

aside a.btn-sidebar {
  display: block;
  text-align: center;
  color: white;
  background-color: #17d292 !important;
  border-radius: 10px;
  width: 100%;
  text-transform: uppercase;
  font-family: Roboto, serif;
  padding: 12px 0;
  transition: ease all 0.3s;
}

aside a.btn-sidebar:hover {
  background-color: #14b37b !important;
}

aside ul {
  margin: 20px 0;
  padding: 0;
  list-style: none;
}

aside ul li {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
  font-family: Roboto, serif;
}

aside ul li .icon {
  font-size: 2rem;
}

aside ul li svg {
  color: #0098d7;
}

aside h2.title {
  color: #0098d7;
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 20px;
}

.cursos-populares {
  border: 1px solid #eaeaea;
  padding: 15px 30px 0 20px;
  margin-bottom: 50px;
}
.curso-popular-item {
  display: grid;
  grid-template-columns: 30% 70%;
  grid-column-gap: 10px;
  -webkit-column-gap: 10px;
          column-gap: 10px;
  align-items: flex-start;
  margin-bottom: 20px;
}
aside .curso-popular-item .title {
  font-size: 0.9rem;
  color: black;
  text-align: left;
}
@media only screen and (max-width: 900px) {
  .all-cursos {
    grid-template-columns: repeat(1, 1fr);
  }

  .curso-single {
    display: block;
  }

  .react-tabs__tab {
    display: block;
    padding: 20px;
  }

  .single-tabs .react-tabs ul li {
    margin-right: 0;
  }

  .single-tabs .react-tabs__tab {
    bottom: 0;
  }

  .react-tabs ul li {
    margin-right: 0;
  }
}

li.react-multi-carousel-item.react-multi-carousel-item--active {
  margin: 0 10px;
}

.botonDeSubir {
  width: 60px;
  height: 60px;
  background-color: var(--color-principal);
  border: 3px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 3;
  bottom: 30px;
  right: 30px;
}

.botonDeSubir svg {
  color: #fff;
}

.botonDeSubir:hover,
.botonDeSubir svg:hover {
  cursor: pointer;
}

.carousel-item {
  position: relative;
}

.carousel-tag {
  position: absolute;
  z-index: 999;
  left: 10%;
  bottom: 50%;
  font-size: 2rem;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  text-align: left;
}

.carousel-tag span {
  display: block;
  color: #0098d7;
}

@media only screen and (max-width: 900px) {
  .carousel-tag {
    left: 20px;
    bottom: 30%;
    font-size: 1rem;
  }
}

/* @media only screen and (max-width: 769px) {
  .carousel-item {
    font-size: 1rem;
  }
}
 */

.custom-button-group {
  width: 100px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 0 auto;
}

.custom-button-group button {
  cursor: pointer;
  width: 46px;
  height: 46px;
  line-height: 46px;
  color: white;
  background: #0098d7;
  border: none;
}

.carousel-parent {
  min-height: 350px;
  margin-top: 30px;
}

.carousel-parent h2 {
  text-align: center;
  margin: 80px 0 30px 0;
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 2rem;
}

ul.react-multi-carousel-track {
  height: 351px;
}

/*
En caso de necesitar sombra para el carusel de la home
.carousel-item::before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgba(0,0,0,.2);
}

*/
.background-cookie {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  background: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: saturate(180%) blur(10px);
          backdrop-filter: saturate(180%) blur(10px);
  display: flex;
}

.modal-cookie {
  position: fixed;
  bottom: 0;
  z-index: 3;
  width: 100%;
  background-color: #edeff5;
  padding: 2%;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.modal-cookie__content p {
  color: black;
  font-size: 1rem;
  margin: 0 0 10px 0;
  color: #838391;
}

.modal-cookie__content p a {
  color: #afb0ba;
}

.modal-cookie__content p a:hover {
  color: #838391;
}

div.modal-cookie__buttons {
  width: 20%;
  display: flex;
  font-size: 1rem;
}

.modal-cookie button {
  height: 30px;
  width: 50%;
  margin: 0 10px;
  background-color: #0099d5;
  color: white;
  border: none;
  border-radius: 5px;
  font-weight: 600;
}

button.modal-cookie__buttons--reject {
  color: #0099d5;
  background-color: #edeff5;
  border: 1px solid #0099d5;
  font-weight: 500;
}

.modal-cookie button:hover {
  cursor: pointer;
}

.modal-cookie.true {
  display: none;
}

.modal-cookie.false {
  display: flex;
}

.ocultar-true {
  display: none !important;
}

.ocultar-fondo-true {
  display: none;
}

/*
.ocultar-false{
  display: flex;
}*/

@media only screen and (max-width: 426px) and (min-width: 320px) {
  div.modal-cookie__buttons {
    width: 80%;
  }

  .modal-cookie__content p {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 768px) and (min-width: 426px) {
  div.modal-cookie__buttons {
    width: 50%;
  }

  .modal-cookie__content p {
    font-size: 0.9rem;
  }
}

.carousel .slide img {
  object-fit: cover;
}

.cta-home {
  font-family: "Roboto Slab", serif;
  background: #0098d7;
  margin: -100px auto 0 auto;
  border-radius: 12px;
  z-index: 1;
  position: relative;
  display: flex;
  padding: 50px 30px;
  color: #171818;
  border-radius: 10px;
}

.cta-home h2 {
  flex: 1 1;
  padding: 0 20px;
  font-size: 1.8rem;
}
.cta-home .countdown-holder {
  flex: 1 1;
  text-align: center;
}

.cta-home .countdown-holder a {
  color: white;
  background: green;
  padding: 20px;
  border-radius: 12px;
  display: inline-block;
  width: 220px;
  text-align: center;
  margin-top: 20px;
  transition: ease all 0.3s;
}

.cta-home .countdown-holder a:hover {
  background: darkgreen;
}

.cta-home .countdown-holder a svg {
  margin-right: 20px;
}

.cta-home .countdown {
  display: flex;
  justify-content: space-around;
  text-align: center;
}
.cta-home .countdown h2 {
  padding: 0;
  color: white;
  font-size: 3rem;
}

.cta-home .countdown span {
  font-weight: 500;
}

.container-countdown {
  width: 1200px;
  max-width: 95vw;
  margin: auto;
}

section.first-section {
  margin: 90px auto 0;
  padding: 20px;
  display: flex;
  position: relative;
  align-items: center;
}

section.first-section .content-home {
  width: 60%;
  padding-left: 40px;
}

section.first-section .content-home p,
section.first-section .content-home h3 {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

section.first-section .content-home h3 {
  font-size: 1.5rem;
}

.content-home h3 {
  color: #0098d7;
  font-weight: bold;
  margin-bottom: 20px;
}

.content-home h3 span {
  color: red;
  text-decoration: line-through;
}

.first-section .circle:before {
  display: block;
  width: 300px;
  height: 300px;
  background: #c9efff;
  border-radius: 50%;
  content: " ";
  position: absolute;
  z-index: -1;
  left: 100px;
  top: 20px;
}

.first-section .circle {
  flex: 2 1;
  padding: 0 0 0 140px;
  font-family: "Roboto Slab", serif;
  font-size: 1.6rem;
  font-weight: bold;
  width: 40%;
}

.first-section .circle span {
  color: #0098d7;
}
.first-section .content {
  flex: 3 1;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}

.first-section .content p {
  margin: 10px 0;
}

.first-section .content h3 {
  color: green;
}

.first-section .content h3 span {
  color: red;
  text-decoration: line-through;
}

.cta-bg {
  width: 100%;
  min-height: 400px;
  background-size: cover;
  padding: 40px 0;
  margin: 50px 0;
}

.cta-bg .flex {
  display: flex;
  align-items: center;
}

.cta-bg .text {
  flex: 1 1;
  font-family: "Open Sans", sans-serif;
  text-align: center;
}

.cta-bg .text h3 {
  color: #0098d7;
  text-align: center;
  font-weight: 700;
  margin: 20px 0;
  font-size: 2rem;
}

.cta-bg .text p {
  color: #0098d7;
  text-align: center;
  font-style: italic;
  font-size: 1.2rem;
}

.cta-bg .text button {
  margin: 20px 0;
  background: rgb(246, 208, 2);
  padding: 6px 12px;
  border: none;
  transition: ease all 0.3s;
  border-radius: 6px;
  font-size: 1.3rem;
}

.cta-bg .text button:hover {
  cursor: pointer;
  background: rgb(255, 170, 0);
}

.cta-bg .moving-img {
  flex: 1 1;
}

.cta-bg .moving-img img {
  width: 600px;
  position: relative;
  z-index: 1;
}

div.tarjeta-home:hover .content-home h3 {
  color: white;
}

div.tarjeta-home .content-home h3 {
  color: #0098d7;
}

.porque-cdo .grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}
.tarjeta-home {
  background: white;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.14);
  min-height: 300px;
  display: flex;
  border-left: 6px solid #0098d7;
  padding: 30px;
  align-items: flex-start;
  justify-content: space-between;
}

.tarjeta-home:hover {
  background: #0098d7;
  cursor: pointer;
  transition: ease all 0.3s;
}

.tarjeta-home svg {
  flex: 1 1;
  font-size: 2rem;
  color: #0098d7;
  margin-right: 20px;
}

.tarjeta-home:hover svg,
.tarjeta-home:hover h3 {
  color: white;
}
.tarjeta-home .content {
  padding-left: 20px;
  flex: 10 1;
}

.tarjeta-home .content h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 700;
  font-size: 1.6rem;
}

.tarjeta-home .content p {
  text-align: justify;
  margin-top: 20px;
}

.counters {
  width: 100%;
  height: 632px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 50px 0;
}

.container-items {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
  grid-row-gap: 20px;
  row-gap: 20px;
  align-items: center;
  height: 100%;
}

.counter-items {
  height: 280px;
  color: white;
  text-align: center;
}
.counter-items svg {
  margin-top: 20px;
  font-size: 4rem;
}
.counter-items h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 3rem;
  font-family: "Roboto Slab", sans-serif;
  color: goldenrod;
}
.counter-items h4 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.8rem;
  color: white;
}

.docentes-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 20px;
  -webkit-column-gap: 20px;
          column-gap: 20px;
}
.docentes-section h2 {
  font-family: "Roboto Slab", sans-serif;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}
.docente {
  text-align: center;
}
.docente h3 {
  margin: 20px 0;
  font-family: "Roboto Slab", sans-serif;
}
.docente span {
  color: #999;
  display: block;
  margin-bottom: 20px;
}

.docentes-list img {
  width: 100%;
}

.garantia {
  margin-top: 50px;
  background: #93c8ee;
  padding-top: 50px;
  color: white;
}

.garantia h2 {
  text-align: center;
  font-size: 3rem;
  font-family: "Roboto Slab", sans-serif;
  color: white;
}

.garantia ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35%;
}

.garantia .img-holder {
  width: 30%;
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
}

.garantia .img-holder img {
  width: 100%;
  margin: 0;
  padding: 0;
}
.garantia ul li {
  margin-top: 50px;
  font-size: 1.3rem;
}

.garantia .container-garantia {
  display: flex;
  flex-direction: row;
}

.garantia-column-one,
.garantia-column-two {
  padding: 0 10px;
  margin-bottom: 30px;
}

div.countdown div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .cta-home {
    flex-direction: column;
    top: 90px;
    height: 210px;
    padding: 20px 15px;
  }

  .cta-home h2 {
    font-size: 1.1rem;
  }

  .cta-home .countdown {
    height: 100%;
    align-items: flex-end;
  }

  .cta-home .countdown h2 {
    font-size: 2rem;
  }

  section.first-section {
    margin-top: 100px;
    flex-direction: column;
    padding-bottom: 0;
  }

  section.first-section .content-home {
    width: 100%;
    padding: 0;
  }

  .first-section .circle {
    padding-left: 0;
    margin: 35px auto 50px;
    width: 100%;
  }

  .first-section .circle:before {
    width: 240px;
    height: 240px;
    right: 50px;
    left: 0;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    margin-bottom: 15px;
  }

  .cta-bg .moving-img img {
    display: none;
  }

  section.porque-cdo {
    width: 100%;
    padding: 0 10px;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
    padding: 0;
    max-width: 100%;
  }

  div.tarjeta-home .svg-inline--fa.fa-w-20 {
    width: 3rem;
    height: 20%;
    margin: 0 0 20px 0;
  }

  div.content-home {
    height: 80%;
  }

  .tarjeta-home {
    flex-direction: column;
    padding: 20px;
    align-items: center;
  }

  div.tarjeta-home .content-home h3 {
    font-size: 1.5rem;
  }

  div.content-home h3,
  div.content-home p {
    font-size: 0.8rem;
  }

  .docente {
    margin-bottom: 20px;
  }

  .container-items {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }

  .counter-items {
    height: -webkit-max-content;
    height: max-content;
  }

  .counter-items svg {
    font-size: 2rem;
  }

  .counter-items h3 {
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .counter-items h4 {
    font-size: 1rem;
  }

  .docentes-list {
    grid-template-columns: 1fr;
  }

  .docente h3 {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  div.docente p {
    font-size: 0.8rem;
  }

  .cta-bg {
    padding-top: 0;
  }

  .cta-bg .flex {
    max-width: 100%;
    margin: 0;
    padding: 0 10px;
  }

  .cta-bg .text {
    width: 100%;
    flex: none;
  }

  .garantia {
    padding: 50px 0;
  }

  .garantia .container-garantia {
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    align-items: center;
  }

  .garantia-column-one,
  .garantia-column-two {
    padding: 0;
  }

  .garantia .img-holder {
    display: none;
  }

  .garantia ul li {
    margin-top: 30px;
  }
}

.linea {
  display: none;
}

@media only screen and (min-width: 320px) and (max-width: 1024px) {
  .linea {
    display: flex;
    width: 65%;
    background-color: rgb(246, 208, 2);
    border-color: rgb(246, 208, 2);
    margin: 30px 0;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .counter-items h4 {
    font-size: 0.8rem;
  }

  section.first-section .content-home p,
  section.first-section .content-home h3 {
    font-size: 1rem;
    margin-bottom: 10px;
  }

  .first-section .circle:before {
    display: block;
    width: 250px;
    height: 250px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 6%;
    top: 20px;
  }

  .cta-home .countdown span {
    font-size: 4vw;
  }

  section.first-section {
    margin-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .first-section .content p {
    font-size: 1.3rem;
  }

  .first-section .content h3 {
    font-size: 1.6rem;
  }

  .first-section .circle {
    padding: 50px 0 0 25px;
    margin-bottom: 50px;
  }

  section.cta-bg {
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .tarjeta-home {
    padding: 30px 15px;
  }

  .tarjeta-home .content p {
    font-size: 1rem;
  }

  .container-items {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .counter-items {
    height: -webkit-max-content;
    height: max-content;
  }

  section.counters svg:not(:root).svg-inline--fa {
    margin-top: 0;
    width: 10vw;
    height: -webkit-max-content;
    height: max-content;
    margin-bottom: 5px;
    color: white;
  }

  .counter-items h3 {
    margin: 5px 0;
  }

  section.docentes-section {
    width: 100%;
    margin: 0;
  }

  .docente h3 {
    font-size: 6vw;
    margin: 10px 0;
  }

  div.docente p {
    font-size: 1.5rem;
  }

  .garantia h2 {
    font-size: 2rem;
  }

  .cta-bg .text h3 {
    font-size: 1.4rem;
  }

  .cta-bg .text p {
    font-size: 1rem;
  }

  .garantia .container-garantia {
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    height: max-content;
    padding: 0 20px 0 20px;
    grid-gap: 10px;
    margin: 0; */
    display: flex;
    flex-direction: column;
    padding: 0 100px;
    align-items: center;
  }

  .docentes-list {
    display: block;
    padding: 0 20px;
  }

  .counter-items h3 {
    font-size: 1.5rem;
  }
  .counter-items h4 {
    font-size: 1.2rem;
  }

  .porque-cdo .grid {
    grid-template-columns: 1fr;
  }

  .cta-bg .flex {
    display: block;
  }

  .cta-home {
    display: block;
    padding: 20px 10px;
    margin: 0;
    bottom: 2.5rem;
  }

  .cta-home h2 {
    padding: 0 20px;
    font-size: 1.6rem;
  }

  .cta-home .countdown h2 {
    padding: 0;
    color: white;
    font-size: 3rem;
  }

  .cta-home .countdown span {
    font-weight: 500;
  }

  .cta-home .countdown-holder {
    padding: 0 20px;
    margin-top: 20px;
  }

  .cta-home .countdown {
    justify-content: space-evenly;
    margin-bottom: 20px;
  }

  .cta-home .countdown-holder a {
    margin: 20px 0;
  }

  .first-section {
    display: block;
  }

  .first-section .circle:before {
    display: block;
    width: 300px;
    height: 300px;
    background: #c9efff;
    border-radius: 50%;
    content: " ";
    position: absolute;
    z-index: -1;
    left: 0;
    top: 20px;
  }

  .first-section .circle span {
    color: #0098d7;
  }

  .docente h3 {
    font-size: 3vw;
  }

  div.docente {
    margin-bottom: 40px;
  }

  section.garantia {
    height: -webkit-max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 50px;
  }

  ul.garantia-column-one {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-two {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  ul.garantia-column-one,
  ul.garantia-column-two {
    margin-bottom: 0;
  }

  .garantia .img-holder {
    width: 100%;
    grid-column-start: 1;
    grid-column-end: 1;
    display: flex;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .garantia .img-holder img {
    /* width: 100%;
    margin: 0 auto;
    padding: 0; */
    display: none;
  }

  footer .blog .entry .img {
    flex: none;
  }

  .garantia ul li {
    margin-top: 20px;
    font-size: 1.5rem;
  }

  .docentes-list img {
    width: 80%;
  }
}

@media only screen and (min-width: 769px) {
  .cta-home {
    top: 40px;
  }
}

.recuadro-cursos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recuadro-cursos img {
  width: 70%;
  border-radius: 5px;
}

.recuadro-cursos h3 {
  font-family: Abel, "San Serif", "Helvetica Neue";
  font-weight: 300;
  font-size: 1.5rem;
  margin-top: 30px;
  transition: 0.2s;
  color: black;
}

.recuadro-cursos h3:hover {
  color: #48a7d4;
  cursor: pointer;
  transition: 0.2s;
}

.primera-seccion-landing-excel {
  background-image: url(/static/media/Fondo-Banner-Principal.40b9e8c8.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  width: 100%;
  height: 600px;
  display: flex;
  padding: 0 100px;
}

.primera-seccion-landing-excel img {
  width: 100%;
  height: 80%;
  align-self: flex-end;
}

.primera-seccion-landing-excel__texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.primera-seccion-landing-excel__texto span {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-weight: 700;
  font-size: 5rem;
  line-height: 90px;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.primera-seccion-landing-excel__texto--microsoft {
  margin-bottom: 20px;
}

.primera-seccion-landing-excel__texto p {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-size: 1.2rem;
  margin-bottom: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.segunda-seccion-landing-excel {
  display: flex;
  width: 100%;
  height: 350px;
  align-items: center;
  padding: 0 40px;
}

.tercera-seccion-landing-excel {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 50px 10vw;
}

.tercera-seccion-landing-excel a {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  transition: 0.2s;
}

.tercera-seccion-landing-excel a:hover {
  color: #0098d7;
  transition: 0.2s;
}

.beneficios-contenedor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px;
  height: -webkit-max-content;
  height: max-content;
}

.beneficios-primera-parte {
  grid-column-start: 1;
  grid-column-end: 2;
}

.beneficios-segunda-parte {
  grid-column-start: 2;
  grid-column-end: 3;
}

.beneficios-tercera-parte {
  grid-column-start: 3;
  grid-column-end: 4;
}

.beneficios-segunda-parte img {
  width: 100%;
}

.beneficios-primera-parte ul li,
.beneficios-tercera-parte ul li {
  margin-bottom: 10px;
}

.beneficios-tercera-parte {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.tercera-seccion-landing-excel .beneficios-tercera-parte a {
  margin-bottom: 20px;
}

.beneficios-tercera-parte a button {
  text-transform: uppercase;
  width: 50%;
  height: 50px;
  color: white;
  background-color: #0098d7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  font-weight: 500;
  transition: 0.2s;
}

.beneficios-tercera-parte a button:hover {
  -webkit-filter: saturate(65%);
          filter: saturate(65%);
  transition: 0.2s;
}

.cuarta-seccion-landing-excel {
  width: 100%;
  height: 450px;
  background-image: url(/static/media/Banner-02-solo.efa1f687.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.cuarta-seccion__logos-cursos,
.cuarta-seccion__texto {
  display: flex;
}

.cuarta-seccion__logos-cursos {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1/2;
  flex-wrap: wrap;
  align-content: center;
}

.cuarta-seccion__logos-cursos img {
  width: 25%;
  margin: 10px;
  position: relative;
  z-index: 1;
}

.cuarta-seccion__foto {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1/2;
  display: flex;
  align-items: flex-end;
  height: inherit;
}

.cuarta-seccion__foto img {
  width: 115%;
  position: relative;
  z-index: 0;
  right: 70px;
}

.cuarta-seccion__texto {
  grid-column-start: 4;
  grid-column-end: 6;
  grid-row: 1/2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.cuarta-seccion__texto span {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  text-align: right;
  font-size: 1.1rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.cuarta-seccion__texto span:nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
  padding-left: 50px;
}

.cuarta-seccion__texto span:nth-child(2) {
  font-weight: bold;
  text-align: right;
  font-size: 1.1rem;
  padding-left: 120px;
}

.cuarta-seccion__texto button {
  width: 60%;
  height: 50px;
  background: linear-gradient(rgb(255, 219, 76) 0%, rgb(255, 205, 2) 100%);
  border: none;
  box-shadow: blur(0px) grayscale(0%) brightness(100%);
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  box-shadow: -10px 15px 60px -15px rgba(0, 0, 0, 0.75);
  transition: 1s;
}

.cuarta-seccion__texto button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  transition: 0.5s;
}

.quinta-seccion-landing-excel {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 50px;
}

.quinta-seccion-landing-excel .carousel-parent h2 {
  margin-top: 0;
}

.sexta-seccion-landing-excel {
  display: grid;
  grid-template-columns: 0.8fr 2fr 3fr 0.8fr;
  background-color: #f1f6fb;
  height: -webkit-max-content;
  height: max-content;
  padding: 50px 0;
}

.sexta-seccion-landing-excel img {
  grid-column: 2 / 3;
  width: 100%;
}

.sexta-seccion-landing-excel__titulos {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__titulos span:nth-child(1) {
  font-family: Roboto, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
  padding-right: 160px;
}

.texto-verde {
  color: #01c47e;
}

.sexta-seccion-landing-excel__titulos span:nth-child(2),
.sexta-seccion-landing-excel__titulos span:nth-child(3) {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
}

.sexta-seccion-landing-excel__titulos span:nth-child(2) {
  text-decoration: line-through;
  color: #ff1000;
}

.sexta-seccion-landing-excel__titulos span:nth-child(3) {
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-excel__texto {
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__beneficios {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion {
  grid-column: 1 / 2;
  margin-right: 10px;
}

.sexta-seccion-landing-excel__beneficios--segunda-seccion {
  grid-column: 2 / 3;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion,
.sexta-seccion-landing-excel__beneficios--segunda-seccion {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-excel__beneficios--primera-seccion span,
.sexta-seccion-landing-excel__beneficios--segunda-seccion span {
  font-family: Roboto, sans-serif;
  letter-spacing: 0.2em;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-excel-landing {
  background-color: black;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-excel-landing path {
  color: black;
  width: 15px;
  height: 15px;
}

.sexta-seccion-landing-excel__beneficios button {
  width: 80%;
  height: 50px;
  background-color: #01c47e;
  border: none;
  border-radius: 5px;
  transition: 1s;
}

.sexta-seccion-landing-excel__beneficios button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  transition: 0.5s;
}

.sexta-seccion-landing-excel__beneficios button a {
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.septima-seccion-landing-excel {
  display: grid;
  grid-template-columns: 0.5fr 4fr 4fr 0.5fr;
  height: -webkit-max-content;
  height: max-content;
}

.septima-seccion-landing-excel div {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.septima-seccion-landing-excel div span,
.septima-seccion-landing-excel button a {
  font-family: Roboto, sans-serif;
}

.septima-seccion-landing-excel div span:nth-child(1) {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 30px;
}

.septima-seccion-landing-excel div span:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 500;
}

.septima-seccion-landing-excel img {
  grid-column: 3 / 4;
  width: 100%;
  align-self: end;
}

div.septima-seccion-landing-excel__seccion-boton {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
}

div.septima-seccion-landing-excel__seccion-boton a {
  width: 55%;
  height: -webkit-max-content;
  height: max-content;
}

div.septima-seccion-landing-excel__seccion-boton a button {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid black;
}

div.septima-seccion-landing-excel__seccion-boton a button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .primera-seccion-landing-excel,
  .segunda-seccion-landing-excel,
  .tercera-seccion-landing-excel,
  .cuarta-seccion-landing-excel,
  .quinta-seccion-landing-excel,
  .sexta-seccion-landing-excel {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 25px 30px;
  }

  .primera-seccion-landing-excel {
    padding: 10px;
  }

  .primera-seccion-landing-excel__texto p {
    font-size: 1rem;
    font-weight: 500;
  }

  .primera-seccion-landing-excel img {
    display: none;
  }

  .primera-seccion-landing-excel__texto {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 2rem;
    display: flex;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    line-height: 40px;
  }

  .segunda-seccion-landing-excel {
    flex-direction: column;
  }

  .segunda-seccion-landing-excel div {
    margin: 15px 0;
  }

  .tercera-seccion-landing-excel a {
    font-size: 1.5rem;
    text-align: center;
  }

  .beneficios-contenedor {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, -webkit-max-content);
    grid-template-rows: repeat(3, max-content);
  }

  .beneficios-primera-parte {
    grid-column: 1 / 1;
    grid-row: 1 / 2;
  }

  .beneficios-segunda-parte {
    grid-column: 1 / 1;
    grid-row: 3 / 4;
  }

  .beneficios-tercera-parte {
    grid-column: 1 / 1;
    grid-row: 2 / 3;
  }

  .tercera-seccion-landing-excel .beneficios-tercera-parte a {
    margin-top: 20px;
  }

  .cuarta-seccion-landing-excel {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .cuarta-seccion__texto {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .cuarta-seccion__foto {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-content: center;
  }

  div.cuarta-seccion__texto span,
  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    text-align: center;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
    margin: 0 auto 10px;
  }

  .quinta-seccion-landing-excel .carousel-parent h2 {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-excel {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .sexta-seccion-landing-excel img,
  .sexta-seccion-landing-excel__texto {
    grid-column: 1 / 2;
  }

  .sexta-seccion-landing-excel img {
    display: none;
  }

  .sexta-seccion-landing-excel__texto {
    grid-row: 1 / 2;
  }

  .sexta-seccion-landing-excel__titulos {
    text-align: center;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
    padding: 0;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(2),
  .sexta-seccion-landing-excel__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-excel__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-excel__beneficios button {
    margin: 10px auto;
  }

  .sexta-seccion-landing-excel__beneficios button a {
    font-size: 1rem;
  }

  .septima-seccion-landing-excel {
    padding-top: 30px;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr -webkit-max-content;
    grid-template-rows: 1fr max-content;
  }

  .septima-seccion-landing-excel div {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .septima-seccion-landing-excel div span:nth-child(1) {
    font-size: 1.5rem;
    line-height: 30px;
    text-align: center;
  }

  .septima-seccion-landing-excel div span:nth-child(2) {
    font-size: 1.2rem;
    text-align: center;
  }

  .septima-seccion-landing-excel img {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  div.septima-seccion-landing-excel__seccion-boton a button {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .primera-seccion-landing-excel,
  .segunda-seccion-landing-excel,
  .tercera-seccion-landing-excel,
  .cuarta-seccion-landing-excel,
  .sexta-seccion-landing-excel,
  .septima-seccion-landing-excel {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-excel {
    padding: 0 20px;
    display: flex;
    height: 100%;
  }

  .primera-seccion-landing-excel img {
    display: none;
  }

  .primera-seccion-landing-excel__texto {
    width: 100%;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 3rem;
  }

  .segunda-seccion-landing-excel {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tercera-seccion-landing-excel a {
    text-align: center;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1.2rem;
  }

  .beneficios-contenedor {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .beneficios-segunda-parte img {
    display: none;
  }

  .tercera-seccion-landing-excel .beneficios-tercera-parte a {
    margin-top: 10px;
  }

  .beneficios-tercera-parte a button {
    font-size: 1.2rem;
  }

  .cuarta-seccion-landing-excel {
    padding: 50px 35px;
  }

  .cuarta-seccion-landing-excel {
    display: flex;
    flex-direction: column;
  }

  .cuarta-seccion__foto img {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    justify-content: center;
  }

  .cuarta-seccion__logos-cursos img {
    width: 10%;
    position: static;
  }

  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    margin: 10px auto;
  }

  .cuarta-seccion__texto span {
    text-align: center;
  }

  .cuarta-seccion__texto button {
    margin: 0 auto;
  }

  .sexta-seccion-landing-excel {
    display: flex;
    flex-direction: column;
    padding: 50px 35px;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    margin-bottom: 0;
  }

  .sexta-seccion-landing-excel img {
    display: none;
  }

  .sexta-seccion-landing-excel__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-excel__beneficios button {
    margin: 10px auto;
  }

  .septima-seccion-landing-excel {
    display: flex;
    flex-direction: column;
    padding: 50px 35px 0;
  }

  .septima-seccion-landing-excel img {
    width: 50%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .primera-seccion-landing-excel {
    padding: 0 35px;
    height: 500px;
  }

  .primera-seccion-landing-excel__texto p {
    font-size: 1rem;
  }

  .primera-seccion-landing-excel img {
    width: 75%;
    height: 75%;
  }

  .primera-seccion-landing-excel__texto {
    justify-content: center;
  }

  .primera-seccion-landing-excel__texto span {
    font-size: 3.5rem;
    margin-bottom: 0;
    line-height: 60px;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1rem;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span:nth-child(2),
  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1):not(.texto-verde) {
    padding-right: 100px;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(1) {
    font-size: 1.8rem;
  }

  .sexta-seccion-landing-excel__titulos span:nth-child(2),
  .sexta-seccion-landing-excel__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-excel__beneficios button a {
    font-size: 1rem;
  }

  div.septima-seccion-landing-excel__seccion-boton a button {
    font-size: 1.2rem;
  }

  .septima-seccion-landing-excel {
    padding-top: 50px;
  }
}

/*primera-seccion-landing-ninos*/

.primera-seccion-landing-ninos {
  background-image: url(/static/media/ninos-Fondo-Banner-Principal-1.112b4e72.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  width: 100%;
  height: 600px;
  display: flex;
  padding: 0 100px;
}

.primera-seccion-landing-ninos img {
  width: 100%;
  height: 80%;
  align-self: flex-end;
}

.primera-seccion-landing-ninos__texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.primera-seccion-landing-ninos__texto span {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-weight: 700;
  font-size: 5rem;
  line-height: 64px;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 auto;
}

.primera-seccion-landing-ninos__texto--microsoft {
  margin-bottom: 20px;
}

.primera-seccion-landing-ninos__texto--aprende,
.primera-seccion-landing-ninos__texto--windows {
    color: rgb(255, 204, 0);
}


.primera-seccion-landing-ninos__texto--so {
    color: white;
  }
  
.primera-seccion-landing-ninos__texto--parrafo{
    padding: 0 5rem;
}

.primera-seccion-landing-ninos__texto p {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-size: 1.2rem;
  margin-bottom: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: white;
  text-align: center;
}

.segunda-seccion-landing-ninos {
  display: flex;
  width: 100%;
  height: 350px;
  align-items: center;
  padding: 0 40px;
}
.segunda-seccion-landing-ninos a {
	margin-bottom: 30px;
	font-size: 2rem;
	font-weight: bold;
	font-family: Montserrat, "Helvetica Neue", Helvetica;
	transition: 0.2s;
}

.tercera-seccion-landing-ninos {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 50px 10vw;
}

.tercera-seccion-landing-ninos a {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  transition: 0.2s;
}

.tercera-seccion-landing-ninos a:hover {
  color: #0098d7;
  transition: 0.2s;
}

.beneficios-contenedor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px;
  height: -webkit-max-content;
  height: max-content;
}

.beneficios-primera-parte {
  grid-column-start: 1;
  grid-column-end: 2;
}

.beneficios-segunda-parte {
  grid-column-start: 2;
  grid-column-end: 3;
}

.beneficios-tercera-parte {
  grid-column-start: 3;
  grid-column-end: 4;
}

.beneficios-segunda-parte img {
  width: 100%;
}

.beneficios-primera-parte ul li,
.beneficios-tercera-parte ul li {
  margin-bottom: 10px;
}

.beneficios-tercera-parte {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.tercera-seccion-landing-ninos .beneficios-tercera-parte a {
  margin-bottom: 20px;
}

.beneficios-tercera-parte a button {
  text-transform: uppercase;
  width: 50%;
  height: 50px;
  color: white;
  background-color: #0098d7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  font-weight: 500;
  transition: 0.2s;
}

.beneficios-tercera-parte a button:hover {
  -webkit-filter: saturate(65%);
          filter: saturate(65%);
  transition: 0.2s;
}

.cuarta-seccion-landing-ninos {
  width: 100%;
  height: 450px;
  background-image: url(/static/media/ninos-fondo-banner-02.266cc30f.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.cuarta-seccion__logos-cursos,
.cuarta-seccion__texto {
  display: flex;
}

.cuarta-seccion__logos-cursos {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1/2;
  flex-wrap: wrap;
  align-content: center;
}

.cuarta-seccion__logos-cursos img {
  width: 25%;
  margin: 10px;
  position: relative;
  z-index: 1;
}

.cuarta-seccion__foto {
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row: 1/2;
  display: flex;
  align-items: flex-end;
  height: inherit;
}

.cuarta-seccion__foto img {
  width: 115%;
  position: relative;
  right: -5rem;
}

.cuarta-seccion__texto {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1/2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: white;
}

.cuarta-seccion__texto span {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 1rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.cuarta-seccion__texto span:nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
  padding-left: 50px;
  position: relative;
  left: 35%;
}

.cuarta-seccion__texto span:nth-child(2) {
  font-weight: bold;
  text-align: right;
  font-size: 1.1rem;
  padding-left: 120px;
  position: relative;
  left: 15%;
}

.cuarta-seccion__texto button {
  width: 60%;
  height: 50px;
  background: linear-gradient(rgb(255, 219, 76) 0%, rgb(255, 205, 2) 100%);
  border: none;
  box-shadow: blur(0px) grayscale(0%) brightness(100%);
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  box-shadow: -10px 15px 60px -15px rgba(0, 0, 0, 0.75);
  transition: 1s;
  margin: 0 auto;
}

.cuarta-seccion__texto button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  transition: 0.5s;
}

.quinta-seccion-landing-ninos {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 50px;
}

.quinta-seccion-landing-ninos .carousel-parent h2 {
  margin-top: 0;
}

.sexta-seccion-landing-ninos {
  display: grid;
  grid-template-columns: 0.8fr 2fr 3fr 0.8fr;
  background-color: #f1f6fb;
  height: -webkit-max-content;
  height: max-content;
}

.sexta-seccion-landing-ninos img {
  grid-column: 2 / 3;
  width: 100%;
  align-self: end;
}

.sexta-seccion-landing-ninos__titulos {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-ninos__titulos span:nth-child(1) {
  font-family: Roboto, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-ninos__titulos span:nth-child(1):not(.texto-verde) {
  padding-right: 160px;
}

.texto-verde {
  color: #0098d7;
}

.sexta-seccion-landing-ninos__titulos span:nth-child(2),
.sexta-seccion-landing-ninos__titulos span:nth-child(3) {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
}

.sexta-seccion-landing-ninos__titulos span:nth-child(2) {
  text-decoration: line-through;
  color: #ff1000;
}

.sexta-seccion-landing-ninos__titulos span:nth-child(3) {
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-ninos__texto {
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
}

.sexta-seccion-landing-ninos__beneficios {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sexta-seccion-landing-ninos__beneficios--primera-seccion {
  grid-column: 1 / 2;
  margin-right: 10px;
}

.sexta-seccion-landing-ninos__beneficios--segunda-seccion {
  grid-column: 2 / 3;
}

.sexta-seccion-landing-ninos__beneficios--primera-seccion,
.sexta-seccion-landing-ninos__beneficios--segunda-seccion {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-ninos__beneficios--primera-seccion span,
.sexta-seccion-landing-ninos__beneficios--segunda-seccion span {
  font-family: Roboto, sans-serif;
  letter-spacing: 0.2em;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-word-landing {
  background-color: black;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-word-landing path {
  color: black;
  width: 15px;
  height: 15px;
}

.sexta-seccion-landing-ninos__beneficios button {
  width: 80%;
  height: 50px;
  background-color: #0098d7;
  border: none;
  border-radius: 5px;
  transition: 1s;
}

.sexta-seccion-landing-ninos__beneficios button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  transition: 0.5s;
}

.sexta-seccion-landing-ninos__beneficios button a {
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.septima-seccion-landing-ninos {
  background-image: url(/static/media/ninos-Fondo-personaje-04.b58227fa.png);
  display: grid;
  grid-template-columns: 0.5fr 4fr 4fr 0.5fr;
  height: -webkit-max-content;
  height: max-content;
}

.septima-seccion-landing-ninos div {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.septima-seccion-landing-ninos div span,
.septima-seccion-landing-ninos button a {
  font-family: Roboto, sans-serif;
}

.septima-seccion-landing-ninos div span:nth-child(1) {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 30px;
}

.septima-seccion-landing-ninos div span:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 500;
}

.septima-seccion-landing-ninos img {
  grid-column: 3 / 4;
  width: 100%;
  align-self: end;
}

div.septima-seccion-landing-ninos__seccion-boton {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
}

div.septima-seccion-landing-ninos__seccion-boton a {
  width: 55%;
  height: -webkit-max-content;
  height: max-content;
}

div.septima-seccion-landing-ninos__seccion-boton a button {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid black;
}

div.septima-seccion-landing-ninos__seccion-boton a button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .primera-seccion-landing-ninos,
  .segunda-seccion-landing-ninos,
  .tercera-seccion-landing-ninos,
  .cuarta-seccion-landing-ninos,
  .quinta-seccion-landing-ninos,
  .sexta-seccion-landing-ninos {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 25px 30px;
  }

  .primera-seccion-landing-ninos {
    padding: 10px;
  }

  .primera-seccion-landing-ninos__texto p {
    font-size: 1rem;
    font-weight: 500;
  }

  .primera-seccion-landing-ninos img {
    display: none;
  }

  .primera-seccion-landing-ninos__texto {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-ninos__texto span {
    font-size: 2rem;
    display: flex;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    line-height: 40px;
  }

  .segunda-seccion-landing-ninos {
    flex-direction: column;
  }

  .segunda-seccion-landing-ninos div {
    margin: 15px 0;
  }

  .tercera-seccion-landing-ninos a {
    font-size: 1.5rem;
    text-align: center;
  }

  .beneficios-contenedor {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, -webkit-max-content);
    grid-template-rows: repeat(3, max-content);
  }

  .beneficios-primera-parte {
    grid-column: 1 / 1;
    grid-row: 1 / 2;
  }

  .beneficios-segunda-parte {
    grid-column: 1 / 1;
    grid-row: 3 / 4;
  }

  .beneficios-tercera-parte {
    grid-column: 1 / 1;
    grid-row: 2 / 3;
  }

  .tercera-seccion-landing-ninos .beneficios-tercera-parte a {
    margin-top: 20px;
  }

  .cuarta-seccion-landing-ninos {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .cuarta-seccion__texto {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .cuarta-seccion__foto {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-content: center;
  }

  div.cuarta-seccion__texto span,
  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    text-align: center;
    left: 0;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
    left: 0;
  }

  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
    margin: 0 auto 10px;
  }

  .quinta-seccion-landing-ninos .carousel-parent h2 {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-ninos {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .sexta-seccion-landing-ninos img,
  .sexta-seccion-landing-ninos__texto {
    grid-column: 1 / 2;
  }

  .sexta-seccion-landing-ninos img {
    display: none;
  }

  .sexta-seccion-landing-ninos__texto {
    grid-row: 1 / 2;
  }

  .sexta-seccion-landing-ninos__titulos {
    text-align: center;
  }

  .sexta-seccion-landing-ninos__titulos span:nth-child(1):not(.texto-verde) {
    padding: 0;
  }

  .sexta-seccion-landing-ninos__titulos span:nth-child(1) {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-ninos__titulos span:nth-child(2),
  .sexta-seccion-landing-ninos__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-ninos__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-ninos__beneficios button {
    margin: 10px auto;
  }

  .sexta-seccion-landing-ninos__beneficios button a {
    font-size: 1rem;
  }

  .septima-seccion-landing-ninos {
    padding-top: 30px;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr -webkit-max-content;
    grid-template-rows: 1fr max-content;
  }

  .septima-seccion-landing-ninos div {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .septima-seccion-landing-ninos div span:nth-child(1) {
    font-size: 1.5rem;
    line-height: 30px;
    text-align: center;
  }

  .septima-seccion-landing-ninos div span:nth-child(2) {
    font-size: 1.2rem;
    text-align: center;
  }

  .septima-seccion-landing-ninos img {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  div.septima-seccion-landing-ninos__seccion-boton a button {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .primera-seccion-landing-ninos,
  .segunda-seccion-landing-ninos,
  .tercera-seccion-landing-ninos,
  .cuarta-seccion-landing-ninos,
  .sexta-seccion-landing-ninos,
  .septima-seccion-landing-ninos {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-ninos {
    padding: 0 20px;
    display: flex;
    height: 100%;
  }

  .primera-seccion-landing-ninos img {
    display: none;
  }

  .primera-seccion-landing-ninos__texto {
    width: 100%;
  }

  .primera-seccion-landing-ninos__texto span {
    font-size: 3rem;
  }

  .segunda-seccion-landing-ninos {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tercera-seccion-landing-ninos a {
    text-align: center;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1.2rem;
  }

  .beneficios-contenedor {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .beneficios-segunda-parte img {
    display: none;
  }

  .tercera-seccion-landing-ninos .beneficios-tercera-parte a {
    margin-top: 10px;
  }

  .beneficios-tercera-parte a button {
    font-size: 1.2rem;
  }

  .cuarta-seccion-landing-ninos {
    padding: 50px 35px;
  }

  .cuarta-seccion-landing-ninos {
    display: flex;
    flex-direction: column;
  }

  .cuarta-seccion__foto img {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    justify-content: center;
  }

  .cuarta-seccion__logos-cursos img {
    width: 10%;
    position: static;
  }

  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    margin: 10px auto;
  }

  .cuarta-seccion__texto span {
    text-align: center;
  }

  .cuarta-seccion__texto button {
    margin: 0 auto;
  }

  .sexta-seccion-landing-ninos {
    display: flex;
    flex-direction: column;
    padding: 50px 35px;
  }

  .sexta-seccion-landing-ninos__titulos span:nth-child(1) {
    margin-bottom: 0;
  }

  .sexta-seccion-landing-ninos img {
    display: none;
  }

  .sexta-seccion-landing-ninos__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-ninos__beneficios button {
    margin: 10px auto;
  }

  .septima-seccion-landing-ninos {
    display: flex;
    flex-direction: column;
    padding: 50px 35px 0;
  }

  .septima-seccion-landing-ninos img {
    width: 50%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .primera-seccion-landing-ninos {
    padding: 0 35px;
    height: 500px;
  }

  .primera-seccion-landing-ninos__texto p {
    font-size: 1rem;
  }

  .primera-seccion-landing-ninos img {
    width: 75%;
    height: 75%;
  }

  .primera-seccion-landing-ninos__texto {
    justify-content: center;
  }

  .primera-seccion-landing-ninos__texto span {
    font-size: 3.5rem;
    margin-bottom: 0;
    line-height: 60px;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1rem;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span:nth-child(2),
  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
  }

  .sexta-seccion-landing-ninos__titulos span:nth-child(1):not(.texto-verde) {
    padding-right: 100px;
  }

  .sexta-seccion-landing-ninos__titulos span:nth-child(1) {
    font-size: 1.8rem;
  }

  .sexta-seccion-landing-ninos__titulos span:nth-child(2),
  .sexta-seccion-landing-ninos__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-ninos__beneficios button a {
    font-size: 1rem;
  }

  div.septima-seccion-landing-ninos__seccion-boton a button {
    font-size: 1.2rem;
  }

  .septima-seccion-landing-ninos {
    padding-top: 50px;
  }
}

.primera-seccion-landing-word {
  background-image: url(/static/media/Word-banner-Principal-1.11d19509.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
  width: 100%;
  height: 600px;
  display: flex;
  padding: 0 100px;
}

.primera-seccion-landing-word img {
  width: 100%;
  height: 80%;
  align-self: flex-end;
}

.primera-seccion-landing-word__texto {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.primera-seccion-landing-word__texto span {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-weight: 700;
  font-size: 5rem;
  line-height: 90px;
  height: -webkit-max-content;
  height: max-content;
  width: -webkit-max-content;
  width: max-content;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.primera-seccion-landing-word__texto--microsoft {
  margin-bottom: 20px;
}

.primera-seccion-landing-word__texto p {
  font-family: Montserrat, "Helvetica Neue", Helvetica, Arial;
  font-size: 1.2rem;
  margin-bottom: 20px;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.segunda-seccion-landing-word {
  display: flex;
  width: 100%;
  height: 350px;
  align-items: center;
  padding: 0 40px;
}

.tercera-seccion-landing-word {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
  padding: 50px 10vw;
}

.tercera-seccion-landing-word a {
  margin-bottom: 30px;
  font-size: 2rem;
  font-weight: bold;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  transition: 0.2s;
}

.tercera-seccion-landing-word a:hover {
  color: #0098d7;
  transition: 0.2s;
}

.beneficios-contenedor {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 50px;
  height: -webkit-max-content;
  height: max-content;
}

.beneficios-primera-parte {
  grid-column-start: 1;
  grid-column-end: 2;
}

.beneficios-segunda-parte {
  grid-column-start: 2;
  grid-column-end: 3;
}

.beneficios-tercera-parte {
  grid-column-start: 3;
  grid-column-end: 4;
}

.beneficios-segunda-parte img {
  width: 100%;
}

.beneficios-primera-parte ul li,
.beneficios-tercera-parte ul li {
  margin-bottom: 10px;
}

.beneficios-tercera-parte {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.tercera-seccion-landing-word .beneficios-tercera-parte a {
  margin-bottom: 20px;
}

.beneficios-tercera-parte a button {
  text-transform: uppercase;
  width: 50%;
  height: 50px;
  color: white;
  background-color: #0098d7;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Montserrat, "Helvetica Neue", Helvetica;
  font-weight: 500;
  transition: 0.2s;
}

.beneficios-tercera-parte a button:hover {
  -webkit-filter: saturate(65%);
          filter: saturate(65%);
  transition: 0.2s;
}

.cuarta-seccion-landing-word {
  width: 100%;
  height: 450px;
  background-image: url(/static/media/Word-Banner-02.c5483e88.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 1fr 3fr 1fr;
  grid-template-rows: 1fr;
}

.cuarta-seccion__logos-cursos,
.cuarta-seccion__texto {
  display: flex;
}

.cuarta-seccion__logos-cursos {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row: 1/2;
  flex-wrap: wrap;
  align-content: center;
}

.cuarta-seccion__logos-cursos img {
  width: 25%;
  margin: 10px;
  position: relative;
  z-index: 1;
}

.cuarta-seccion__foto {
  grid-column-start: 5;
  grid-column-end: 6;
  grid-row: 1/2;
  display: flex;
  align-items: flex-end;
  height: inherit;
}

.cuarta-seccion__foto img {
  width: 115%;
  position: relative;
  right: 0;
}

.cuarta-seccion__texto {
  grid-column-start: 3;
  grid-column-end: 5;
  grid-row: 1/2;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  color: white;
}

.cuarta-seccion__texto span {
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 1rem;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.cuarta-seccion__texto span:nth-child(1) {
  font-size: 2rem;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
  padding-left: 50px;
  position: relative;
  left: 35%;
}

.cuarta-seccion__texto span:nth-child(2) {
  font-weight: bold;
  text-align: right;
  font-size: 1.1rem;
  padding-left: 120px;
  position: relative;
  left: 15%;
}

.cuarta-seccion__texto button {
  width: 60%;
  height: 50px;
  background: linear-gradient(rgb(255, 219, 76) 0%, rgb(255, 205, 2) 100%);
  border: none;
  box-shadow: blur(0px) grayscale(0%) brightness(100%);
  border-radius: 5px;
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: 500;
  box-shadow: -10px 15px 60px -15px rgba(0, 0, 0, 0.75);
  transition: 1s;
  margin: 0 auto;
}

.cuarta-seccion__texto button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.1);
          filter: brightness(1.1);
  transition: 0.5s;
}

.quinta-seccion-landing-word {
  width: 100%;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 50px;
}

.quinta-seccion-landing-word .carousel-parent h2 {
  margin-top: 0;
}

.sexta-seccion-landing-word {
  display: grid;
  grid-template-columns: 0.8fr 2fr 3fr 0.8fr;
  background-color: #f1f6fb;
  height: -webkit-max-content;
  height: max-content;
}

.sexta-seccion-landing-word img {
  grid-column: 2 / 3;
  width: 100%;
  align-self: end;
}

.sexta-seccion-landing-word__titulos {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-word__titulos span:nth-child(1) {
  font-family: Roboto, sans-serif;
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-word__titulos span:nth-child(1):not(.texto-verde) {
  padding-right: 160px;
}

.texto-verde {
  color: #0098d7;
}

.sexta-seccion-landing-word__titulos span:nth-child(2),
.sexta-seccion-landing-word__titulos span:nth-child(3) {
  font-family: Roboto, sans-serif;
  font-size: 1.5rem;
}

.sexta-seccion-landing-word__titulos span:nth-child(2) {
  text-decoration: line-through;
  color: #ff1000;
}

.sexta-seccion-landing-word__titulos span:nth-child(3) {
  font-weight: 500;
  margin-bottom: 20px;
}

.sexta-seccion-landing-word__texto {
  grid-column: 3 / 4;
  display: flex;
  flex-direction: column;
  padding: 50px 0;
}

.sexta-seccion-landing-word__beneficios {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.sexta-seccion-landing-word__beneficios--primera-seccion {
  grid-column: 1 / 2;
  margin-right: 10px;
}

.sexta-seccion-landing-word__beneficios--segunda-seccion {
  grid-column: 2 / 3;
}

.sexta-seccion-landing-word__beneficios--primera-seccion,
.sexta-seccion-landing-word__beneficios--segunda-seccion {
  display: flex;
  flex-direction: column;
}

.sexta-seccion-landing-word__beneficios--primera-seccion span,
.sexta-seccion-landing-word__beneficios--segunda-seccion span {
  font-family: Roboto, sans-serif;
  letter-spacing: 0.2em;
  font-size: 0.8rem;
  font-weight: 500;
  margin-bottom: 10px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-word-landing {
  background-color: black;
  width: 12px;
  height: 12px;
  margin-right: 5px;
}

svg.svg-inline--fa.fa-circle.fa-w-16.icono-word-landing path {
  color: black;
  width: 15px;
  height: 15px;
}

.sexta-seccion-landing-word__beneficios button {
  width: 80%;
  height: 50px;
  background-color: #0098d7;
  border: none;
  border-radius: 5px;
  transition: 1s;
}

.sexta-seccion-landing-word__beneficios button:hover {
  cursor: pointer;
  -webkit-filter: brightness(1.2);
          filter: brightness(1.2);
  transition: 0.5s;
}

.sexta-seccion-landing-word__beneficios button a {
  text-transform: uppercase;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
  color: #fff;
}

.septima-seccion-landing-word {
  background-image: url(/static/media/Word-Fondo-personaje-04.2a1bea89.jpg);
  display: grid;
  grid-template-columns: 0.5fr 4fr 4fr 0.5fr;
  height: -webkit-max-content;
  height: max-content;
}

.septima-seccion-landing-word div {
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.septima-seccion-landing-word div span,
.septima-seccion-landing-word button a {
  font-family: Roboto, sans-serif;
}

.septima-seccion-landing-word div span:nth-child(1) {
  font-size: 3.5rem;
  font-weight: bold;
  line-height: 50px;
  margin-bottom: 30px;
}

.septima-seccion-landing-word div span:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 500;
}

.septima-seccion-landing-word img {
  grid-column: 3 / 4;
  width: 100%;
  align-self: end;
}

div.septima-seccion-landing-word__seccion-boton {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
}

div.septima-seccion-landing-word__seccion-boton a {
  width: 55%;
  height: -webkit-max-content;
  height: max-content;
}

div.septima-seccion-landing-word__seccion-boton a button {
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  text-transform: uppercase;
  background-color: transparent;
  border: 1px solid black;
}

div.septima-seccion-landing-word__seccion-boton a button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 425px) {
  .primera-seccion-landing-word,
  .segunda-seccion-landing-word,
  .tercera-seccion-landing-word,
  .cuarta-seccion-landing-word,
  .quinta-seccion-landing-word,
  .sexta-seccion-landing-word {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    padding: 25px 30px;
  }

  .primera-seccion-landing-word {
    padding: 10px;
  }

  .primera-seccion-landing-word__texto p {
    font-size: 1rem;
    font-weight: 500;
  }

  .primera-seccion-landing-word img {
    display: none;
  }

  .primera-seccion-landing-word__texto {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-word__texto span {
    font-size: 2rem;
    display: flex;
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
    line-height: 40px;
  }

  .segunda-seccion-landing-word {
    flex-direction: column;
  }

  .segunda-seccion-landing-word div {
    margin: 15px 0;
  }

  .tercera-seccion-landing-word a {
    font-size: 1.5rem;
    text-align: center;
  }

  .beneficios-contenedor {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, -webkit-max-content);
    grid-template-rows: repeat(3, max-content);
  }

  .beneficios-primera-parte {
    grid-column: 1 / 1;
    grid-row: 1 / 2;
  }

  .beneficios-segunda-parte {
    grid-column: 1 / 1;
    grid-row: 3 / 4;
  }

  .beneficios-tercera-parte {
    grid-column: 1 / 1;
    grid-row: 2 / 3;
  }

  .tercera-seccion-landing-word .beneficios-tercera-parte a {
    margin-top: 20px;
  }

  .cuarta-seccion-landing-word {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .cuarta-seccion__texto {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .cuarta-seccion__foto {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
    justify-content: center;
  }

  div.cuarta-seccion__texto span,
  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    text-align: center;
    left: 0;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
    left: 0;
  }

  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
    margin: 0 auto 10px;
  }

  .quinta-seccion-landing-word .carousel-parent h2 {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-word {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, -webkit-max-content);
    grid-template-rows: repeat(2, max-content);
  }

  .sexta-seccion-landing-word img,
  .sexta-seccion-landing-word__texto {
    grid-column: 1 / 2;
  }

  .sexta-seccion-landing-word img {
    display: none;
  }

  .sexta-seccion-landing-word__texto {
    grid-row: 1 / 2;
  }

  .sexta-seccion-landing-word__titulos {
    text-align: center;
  }

  .sexta-seccion-landing-word__titulos span:nth-child(1):not(.texto-verde) {
    padding: 0;
  }

  .sexta-seccion-landing-word__titulos span:nth-child(1) {
    font-size: 1.5rem;
  }

  .sexta-seccion-landing-word__titulos span:nth-child(2),
  .sexta-seccion-landing-word__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-word__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-word__beneficios button {
    margin: 10px auto;
  }

  .sexta-seccion-landing-word__beneficios button a {
    font-size: 1rem;
  }

  .septima-seccion-landing-word {
    padding-top: 30px;
    padding-bottom: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr -webkit-max-content;
    grid-template-rows: 1fr max-content;
  }

  .septima-seccion-landing-word div {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  .septima-seccion-landing-word div span:nth-child(1) {
    font-size: 1.5rem;
    line-height: 30px;
    text-align: center;
  }

  .septima-seccion-landing-word div span:nth-child(2) {
    font-size: 1.2rem;
    text-align: center;
  }

  .septima-seccion-landing-word img {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  div.septima-seccion-landing-word__seccion-boton a button {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 426px) and (max-width: 768px) {
  .primera-seccion-landing-word,
  .segunda-seccion-landing-word,
  .tercera-seccion-landing-word,
  .cuarta-seccion-landing-word,
  .sexta-seccion-landing-word,
  .septima-seccion-landing-word {
    width: 100%;
    height: -webkit-max-content;
    height: max-content;
  }

  .primera-seccion-landing-word {
    padding: 0 20px;
    display: flex;
    height: 100%;
  }

  .primera-seccion-landing-word img {
    display: none;
  }

  .primera-seccion-landing-word__texto {
    width: 100%;
  }

  .primera-seccion-landing-word__texto span {
    font-size: 3rem;
  }

  .segunda-seccion-landing-word {
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .tercera-seccion-landing-word a {
    text-align: center;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1.2rem;
  }

  .beneficios-contenedor {
    display: block;
    height: -webkit-max-content;
    height: max-content;
  }

  .beneficios-segunda-parte img {
    display: none;
  }

  .tercera-seccion-landing-word .beneficios-tercera-parte a {
    margin-top: 10px;
  }

  .beneficios-tercera-parte a button {
    font-size: 1.2rem;
  }

  .cuarta-seccion-landing-word {
    padding: 50px 35px;
  }

  .cuarta-seccion-landing-word {
    display: flex;
    flex-direction: column;
  }

  .cuarta-seccion__foto img {
    display: none;
  }

  .cuarta-seccion__logos-cursos {
    justify-content: center;
  }

  .cuarta-seccion__logos-cursos img {
    width: 10%;
    position: static;
  }

  .cuarta-seccion__texto span:nth-child(1),
  .cuarta-seccion__texto span:nth-child(2) {
    padding: 0;
    margin: 10px auto;
  }

  .cuarta-seccion__texto span {
    text-align: center;
  }

  .cuarta-seccion__texto button {
    margin: 0 auto;
  }

  .sexta-seccion-landing-word {
    display: flex;
    flex-direction: column;
    padding: 50px 35px;
  }

  .sexta-seccion-landing-word__titulos span:nth-child(1) {
    margin-bottom: 0;
  }

  .sexta-seccion-landing-word img {
    display: none;
  }

  .sexta-seccion-landing-word__beneficios {
    display: flex;
    flex-direction: column;
  }

  .sexta-seccion-landing-word__beneficios button {
    margin: 10px auto;
  }

  .septima-seccion-landing-word {
    display: flex;
    flex-direction: column;
    padding: 50px 35px 0;
  }

  .septima-seccion-landing-word img {
    width: 50%;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .primera-seccion-landing-word {
    padding: 0 35px;
    height: 500px;
  }

  .primera-seccion-landing-word__texto p {
    font-size: 1rem;
  }

  .primera-seccion-landing-word img {
    width: 75%;
    height: 75%;
  }

  .primera-seccion-landing-word__texto {
    justify-content: center;
  }

  .primera-seccion-landing-word__texto span {
    font-size: 3.5rem;
    margin-bottom: 0;
    line-height: 60px;
  }

  .beneficios-primera-parte ul li,
  .beneficios-tercera-parte ul li {
    font-size: 1rem;
  }

  .cuarta-seccion__texto span:nth-child(1) {
    font-size: 1.5rem;
  }

  .cuarta-seccion__texto span:nth-child(2),
  .cuarta-seccion__texto span {
    font-size: 1rem;
  }

  .cuarta-seccion__texto button {
    font-size: 1rem;
  }

  .sexta-seccion-landing-word__titulos span:nth-child(1):not(.texto-verde) {
    padding-right: 100px;
  }

  .sexta-seccion-landing-word__titulos span:nth-child(1) {
    font-size: 1.8rem;
  }

  .sexta-seccion-landing-word__titulos span:nth-child(2),
  .sexta-seccion-landing-word__titulos span:nth-child(3) {
    font-size: 1.2rem;
  }

  .sexta-seccion-landing-word__beneficios button a {
    font-size: 1rem;
  }

  div.septima-seccion-landing-word__seccion-boton a button {
    font-size: 1.2rem;
  }

  .septima-seccion-landing-word {
    padding-top: 50px;
  }
}

.post-content {
	margin: 0 auto;
	font-family: "Open Sans", sans-serif;
	padding: 0 35px;
}

.has-text-align-center {
	text-align: center !important;
}
main {
  width: 100%;
}

.questions-section {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin: 100px auto;
}

.section-title-questions {
  font-family: Roboto;
  /*font-size: 2vw;*/
  font-weight: 800;
  height: -webkit-max-content;
  height: max-content;
  margin-bottom: 2rem;
}

h3 {
  font-family: Roboto;
  /*font-size: 1.1vw;*/
  font-weight: 500;
  margin: 0;
  color: #0099d5;
}

.question h3 {
  color: var(--color-principal);
  font-size: 1rem;
  text-align: left;
}

.responses {
  font-family: Roboto;
  margin: 20px 0 15px 0;
  display: none;
}

.responses.mostrar {
  font-family: Roboto;
  display: block;
  height: -webkit-max-content;
  height: max-content;
}

.responses p {
  font-size: 0.9rem;
  text-align: left;
}

.ocultar {
  display: none;
}

.question {
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  cursor: pointer;
  padding: 15px 0;
}

.questions-button {
  background-color: white;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 15px;
  height: -webkit-max-content;
  height: max-content;
  padding: 0 25px;
}

svg.svg-inline--fa.fa-greater-than.fa-w-12 path {
  color: #0099d5;
  transition: all 0.8s ease;
}

svg.svg-inline--fa.fa-greater-than.fa-w-12.icon-preguntas path {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transition: all 0.8s ease;
}

.questions-button-initial-state {
  height: 40px;
}

.subrayado {
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

.enlace-cursos {
  color: #0099d5;
  -webkit-text-decoration: dashed;
          text-decoration: dashed;
}

@media only screen and (max-width: 425px) {
  .question h3 {
    font-size: 1rem;
    text-align: left;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  aside {
    display: none;
  }

  .pagination {
    justify-content: center;
  }

  .section-title-questions {
    font-size: 5vw;
  }

  .questions-section p {
    font-size: 0.9rem;
  }

  .questions-section {
    margin: 30px auto;
    width: 90%;
  }

  .question svg.icon-preguntas {
    width: 3vw;
  }
}

@media only screen and (max-width: 1023px) and (min-width: 426px) {
  .questions-section {
    width: 85%;
  }
}

