.input-group .nombre,
.input-group .email {
  width: 47%;
}

.svg.svg-inline--fa.fa-caret-left.fa-w-6 path {
  color: white;
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .input-group .nombre,
  .input-group .email {
    width: 100%;
    margin-bottom: 10px;
  }
}
