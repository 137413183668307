@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");

.recuadro-cursos {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recuadro-cursos img {
  width: 70%;
  border-radius: 5px;
}

.recuadro-cursos h3 {
  font-family: Abel, "San Serif", "Helvetica Neue";
  font-weight: 300;
  font-size: 1.5rem;
  margin-top: 30px;
  transition: 0.2s;
  color: black;
}

.recuadro-cursos h3:hover {
  color: #48a7d4;
  cursor: pointer;
  transition: 0.2s;
}
