.nosotros-holder {
  margin-top: 50px;
  margin-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  padding: 0 10%;
}

.nosotros-holder h2 {
  margin-bottom: 20px;
  font-family: Roboto, sans-serif;
  font-size: 1.8rem;
}

.nosotros-holder p {
  margin-bottom: 1.2rem;
}

.form-nosotros ul {
  display: flex;
  background: #0098d7;
  color: white;
  font-family: Roboto, sans-serif;
  text-transform: uppercase;
  padding: 1% 1% 0;
  font-weight: 700;
  border: none;
}

.form-nosotros ul li {
  margin-right: 30px;
}
.form-nosotros .react-tabs__tab--selected {
  padding: 20px;
  border-radius: 0;
  border: none;
}

.react-tabs__tab-panel {
  margin-top: 30px;
}

@media only screen and (max-width: 426px) and (min-width: 320px) {
  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .nosotros-holder p {
    font-size: 4vw;
  }

  .cuarta-seccion-nosotros #react-tabs-2 {
    margin-right: 0;
  }

  .cuarta-seccion-nosotros li#react-tabs-2,
  li#react-tabs-0 {
    text-align: center;
  }

  .form-nosotros .contact-form,
  .cuarta-seccion-nosotros p,
  .primera-seccion-nosotros {
    padding: 0 10%;
  }

  form.contact-form button.button-nosotros-form {
    min-width: 100px;
    padding: 8px 25px;
  }

  img.img-fluid {
    margin: 20px 0;
  }

  ul.react-tabs__tab-list {
    display: flex;
    justify-content: space-evenly;
  }

  li#react-tabs-2,
  li#react-tabs-0 {
    padding: 20px;
  }
}

@media only screen and (max-width: 769px) and (min-width: 426px) {
  .nosotros-holder {
    display: flex;
    flex-direction: column;
    padding: 0 10%;
    align-items: center;
  }

  .nosotros-holder p {
    font-size: 0.8rem;
  }

  .form-nosotros ul {
    display: flex;
  }

  .nosotros-holder div {
    width: 100%;
  }
}
