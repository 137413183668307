.section-title{
    height: 168px;
    background-size: cover;
    overflow: hidden;
    display: flex;
}

.section-title h1{
    color:white;
    font-family: "Roboto Slab", sans-serif;
    font-size: 1.8rem;
    text-transform: uppercase;
    text-shadow: 1px 1px 1px rgba(0,0,0,.3);
}
@media only screen and (max-width: 900px) {
    .section-title h1{
        font-size: 1.3rem;
    }
}